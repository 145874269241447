import './middlewares/polyfill';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import React from 'react';
import {
  Route, Switch, HashRouter, Redirect,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/apm';
import { unregister } from './middlewares/registerServiceWorker';
import createStore from './store/store';
import Checkout from './views/pages/checkout';
import Resume from './views/pages/resume';
import Page404 from './views/pages/page404';
import Page500 from './views/pages/page500';
import InvalidToken from './views/pages/invalidToken';
import packageJson from '../package.json';
import Meta from './lib/meta';

import 'font-awesome/css/font-awesome.min.css';
import './scss/style.scss';
import 'react-toastify/dist/ReactToastify.css';

// eslint-disable-next-line no-underscore-dangle
const store = createStore(window.__INITIAL_STATE__);

if (process.env.REACT_APP_STAGE !== 'PROD') {
  Meta();
}

Sentry.init({
  dsn: 'https://b6420e1f382a45299a2cf9c355af7933@o438344.ingest.sentry.io/5402848',
  integrations: [
    new Integrations.Tracing(),
  ],
  tracesSampleRate: 1.0,
  release: `${packageJson.name}@${packageJson.version}`,
  environment: process.env.REACT_APP_STAGE,
  autoSessionTracking: true,
});

ReactDOM.render(
  <Provider store={store}>
    <div>
      <ToastContainer autoClose={8000} />
      <HashRouter>
        <Switch>
          <Route exact path="/checkout/:token" name="Checkout" component={Checkout} />
          <Route exact path="/resume" name="Resumo" component={Resume} />
          <Route exact path="/404" name="Página 404" component={Page404} />
          <Route exact path="/500" name="Página 500" component={Page500} />
          <Route exact path="/invalid-token" name="Token Inválido" component={InvalidToken} />
          <Redirect from="*" to="/404" />
        </Switch>
      </HashRouter>
      <span className="version">
        v
        {packageJson.version}
      </span>
    </div>
  </Provider>,
  document.getElementById('root'),
);
unregister();
