export default {
  END_POINT: process.env.REACT_APP_API_BASE_URL || 'https://homol.partner.gateway.pay2b.net',
  EXPIRATION_AUTH: 3600000 || process.env.MAX_IDLE_TIME,
  EXPIRATION_FORM: 5, // Minuts
  DEBUG: {
    LEVEL: 'debug',
    AVAILABLE: true,
    LOCAL: true,
  },
  BRAINTREE: {
    MERCHANT_ID: process.env.BRAINTREE_MERCHANT_ID || 'jr7yzsfyf5zjnpyd',
    PUBLIC_KEY: process.env.BRAINTREE_PUBLIC_KEY || '5dg78b8y9wndtyh5',
    PRIVATE_KEY: process.env.BRAINTREE_PRIVATE_KEY || '9753b17e53ca9b7d8e4cedcf11b60a2d',
    IS_SANDBOX: process.env.BRAINTREE_IS_SANDBOX || true,
  },
  CHECKOUT: {
    URL: '/checkout/subscription',
    PAY: '/pay',
  },
  RESUME: {
    URL: '/checkout/subscription',
  },
  CARD: {
    URL: '/checkout/subscription',
    CREDIT_CARD: '/credit_card',
  },
  TOKEN: {
    URL: '/checkout/subscription',
    REQUEST: '/token',
    CONFIRM: '/confirm',
  },
};
