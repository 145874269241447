export default {
  CompanyName: '',
  CompanyLogo: '',
  CustomerId: '',
  FirstName: '',
  LastName: '',
  FullName: '',
  Email: '',
  Document: '',
  ProposalNumber: '',
  Description: '',
  Type: '',
  IconType: '',
  TotalPrize: 0,
  VoucherValueId: '',
  VoucherOptionId: '',
  VoucherValue: 0,
  BalancePay: 0,
  InstallmentCount: 0,
  InstallmentValue: 0,
  Cards: [],
  PublicKey: '',
  Status: '',
  VoucherStatus: '',
  ButtonConfirmBackground: '',
  ButtonConfirmText: '',
  TopLineBackground: '',
  Cars: [],
  Phones: [],
  OnlyCreditCard: false,
};
