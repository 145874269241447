import { POST_RESUME, LIST_RESUME } from '../actionTypes/resume';

const BASE = {
  state: false,
  data: null,
  success: true,
};

const INITIAL_STATE = {
  statePostResume: BASE,
  listResume: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_RESUME:
      return { ...state, statePostResume: action.payload };
    case LIST_RESUME:
      return { ...state, listResume: action.payload };
    default:
      return state;
  }
};
