export default {
  LOADING: 'Aguardando...',
  NOTIFY_SUCCESS: 'Usuário cadastrado com sucesso!',
  TITLE: 'Checkout',
  TITLE_PAYMENT: 'Pagamento',
  SUB_TITLE_PAYMENT: 'Veja abaixo as formas de pagamento disponíveis.',
  TITLE_RESUME: 'Resumo do Pagamento',
  SUB_TITLE_RESUME: 'Confira as informações do seu pedido.',
  FORM: {
    CONTINUE_BUTTON: 'Confirmar pagamento',
    CONFIRM: 'Confirmar',
    FINISH_BUTTON: 'CONFIRMAR PAGAMENTO',
    SEND_BUTTON: 'Enviar',
    CONFIRM_BUTTON: 'Enviar',
    TITLE_CARD_SECTION: 'Cartão de Crédito',
    TITLE_VERIFY_SECTION: 'Verificação por SMS',
    TITLE_CONFIRMATION_SECTION: 'Confirmação do SMS',
    TITLE_CARDS: 'Escolha o cartão que deseja usar pela bandeira e final.',
    PHONES_FIELD: 'Celular:',
    NAME_FIELD: 'Nome completo do titular:',
    NAME_PLACEHOLDER: 'Nome completo',
    CREDIT_CARD_NUMBER_FIELD: 'Número do cartão de crédito:',
    CREDIT_CARD_NUMBER_PLACEHOLDER: 'Número do cartão',
    SMS_TOKEN: 'Código recebido',
    SMS_TOKEN_PLACEHOLDER: '123456',
    DATE_BIRTH_FIELD: 'Data de nascimento:',
    DATE_BIRTH_PLACEHOLDER: 'Data de nascimento',
    DOCUMENT_FIELD: 'Documento:',
    DOCUMENT_PLACEHOLDER: 'CPF do titular do cartão',
    CVV_FIELD: 'Cód. de segurança:',
    CVV_PLACEHOLDER: 'Cód. de segurança',
    DATE_VALIDATE_FIELD: 'Data de validade:',
    DATE_VALIDATE_PLACEHOLDER: 'Data de vencimento',
    INSTALLMENTS_FIELD: 'Parcelamento:',
    INSTALLMENTS_PLACEHOLDER: 'Selecione uma parcela',
    SAVE_CARD_FIELD: 'Os valores recorrentes serão lançados nesse cartão',
    TERMS_FIELD: 'Termos e aceite',
  },
  DETAILS: {
    NAME: 'Nome:',
    PROPOSAL_NUMBER: 'Número da Proposta:',
    VEHICLE_PLATE: 'Placa do Veículo',
    TOTAL_PRIZE: 'Valor Total:',
    BALANCE_PAY: 'Crédito:',
    VALUE: 'Valor restante a ser pago',
    BALANCE_PAID: 'Valor final em BRL',
    SPLIT: 'Parcelado em',
    CPF: 'CPF:',
    CNPJ: 'CNPJ:',
  },
};
