import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import Header from '../../containers/defaultHeader';
import Footer from '../../containers/defaultFooter';

export default () => (
  <div className="container-page">
    <Header />
    <div className="app flex-row align-items-center h-100">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <div className="clearfix" style={{ marginLeft: '80px' }}>
              <h1 className="float-left display-3 mr-4">500</h1>
              <h4 className="pt-3">Nós temos um problema. =(</h4>
              <p className="text-muted float-left">A página está temporariamente indisponível.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <Footer />
  </div>
);
