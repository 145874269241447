/* eslint-disable no-unused-vars */
/* eslint-disable comma-dangle */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint no-extend-native: ["error", { "exceptions": ["String", "Number"] }] */

import moment from "moment";

export const DocumentFormat = (document) => {
  let value = document.replace(/[^\d]/g, "");

  if (!value) return "-";

  if (value.length <= 11) {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  value = value.replace(
    /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
    "$1.$2.$3/$4-$5"
  );

  return value;
};

export const DateValidateMask = (value) =>
  value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1/$2");

export const DateBirthMask = (value) =>
  value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1/$2");

export const CreditCardMask = (value) =>
  value
    .replace(/\D/g, "")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(\d{4})(\d)/, "$1 $2");

export const CpfMask = (value) =>
  value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");

export const CnpjMask = (value) => {
  let v = value;
  v = v.replace(/\D/g, "");
  v = v.replace(/^(\d{2})(\d)/, "$1.$2");
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
  v = v.replace(/(\d{4})(\d)/, "$1-$2");

  return v;
};

export const DocumentMask = (value) => {
  let v = value;
  v = v.replace(/\D/g, "");

  if (v.length <= 11) {
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else {
    v = v.replace(/^(\d{2})(\d)/, "$1.$2");
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
    v = v.replace(/(\d{4})(\d)/, "$1-$2");
  }

  return v;
};

export const PhoneCompleteMask = (value) => {
  let v = value;
  v = v.replace(/\D/g, "");

  if (v.length <= 10) {
    v = v.replace(/(\d{0})(\d)/, "($1$2");
    v = v.replace(/(\d{2})(\d)/, "$1) $2");
    v = v.replace(/(\d{4})(\d)/, "$1-$2");
  } else {
    v = v.replace(/(\d{0})(\d)/, "($1$2");
    v = v.replace(/(\d{2})(\d)/, "$1) $2");
    v = v.replace(/(\d{5})(\d)/, "$1-$2");
  }

  return v;
};

const brlFormatterNative = new Intl.NumberFormat("pt-Br", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
});

export const brlFormatter = {
  format: (value) => brlFormatterNative.format(value / 100),
};

export function formatCPF(document) {
  return document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function formatCNPJ(document) {
  return document.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5"
  );
}

export function formatCEP(document) {
  return document.replace(/(\d{5})/, "$1-");
}

export function formatPhone(document) {
  if (document.length === 8) {
    return document.replace(/(\d{4})/, "$1-");
  }
  return document.replace(/(\d{5})/, "$1-");
}

export function mask(number, masked) {
  const numberF = number.replace(/[^0-9]/g, "");
  let numberPos = 0;
  return numberF
    .split("")
    .map((num, i) => {
      numberPos += 1;
      if (masked[numberPos] === "0") {
        return num;
      }
      return masked[i + numberPos] + num;
    })
    .join("");
}

String.prototype.nameMask = function () {
  return this.replace(/[^A-Za-zÀ-Úà-ú\s]/g, "");
};

String.prototype.cpfMask = function () {
  return mask(this, "000.000.000-00");
};

String.prototype.cnpjMask = function () {
  return mask(this, "00.000.000/0000-00");
};

String.prototype.zipcodeMask = function () {
  return mask(this, "00000-000");
};

String.prototype.phoneMask = function () {
  const cleanPhone = this.replace(/[^0-9]/g, "");
  return cleanPhone.length <= 8
    ? mask(this, "0000-0000")
    : mask(this, "00000-0000");
};

Number.prototype.BRLCurrency = function () {
  return brlFormatterNative.format(this / 100);
};

String.prototype.toDateLocale = function () {
  return moment(this).format("DD/MM/YYYY");
};

String.prototype.toDateTimeLocale = function () {
  return moment(this).format("DD/MM/YYYY HH:mm:ss");
};

String.prototype.toTimeLocale = function () {
  return moment(this).format("HH:mm:ss");
};
