import { axiosInstance as axios } from '../lib/axios';
import Config from '../config/environments';
import { POST_CHECKOUT } from '../actionTypes/checkout';
import { viewModelToModel } from '../mapper/checkout';
import updateState from './common/common';

export const postDataCheckout = (data, token, deviceData) => async (dispatch) => {
  const base = {
    type: POST_CHECKOUT,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.CHECKOUT.URL}/${token}${Config.CHECKOUT.PAY}`;

  const config = {
    headers: {
      'x-device-data': deviceData,
    },
  };

  axios.post(url, data, config).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewModelToModel(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const postDataCheckout2 = (data, token) => async (dispatch) => {
  const base = {
    type: POST_CHECKOUT,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.CHECKOUT.URL}/${token}${Config.CHECKOUT.PAY}`;

  axios.post(url, data).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewModelToModel(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};
