import { Base64 } from 'js-base64';
import Model from '../model/checkout';
import { DocumentFormat } from '../lib/mask';

export const viewModelToModel = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.AuthCode = view.authCode;
    model.AuthorizedAt = view.authorizedAt;
    model.Status = view.status;

    return model;
  } catch (error) {
    throw new Error(`CheckoutMapper - viewModelToModel = ${error}`);
  }
};

export const viewsModelToModels = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.checkouts.forEach((model) => {
      const view = viewModelToModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`CheckoutMapper - viewsModelToModels = ${error}`);
  }
};

export const modelToViewModel = (model, props, geolocalization, cardState) => {
  try {
    if (!model) return null;

    const geoAvailable = geolocalization && geolocalization.isGeolocationAvailable
      && geolocalization.isGeolocationEnabled;

    const view = {
      device: {
        ip: model.IpAddress,
        geolocation: {},
      },
      userAgent: window.navigator.userAgent,
      fingerprint: Base64.encode(model.Finger),
    };

    if (props.data.BalancePay > 0) {
      view.paymentInstrument = {
        type: 'CREDIT_CARD',
        value: props.data.BalancePay,
        installmentCount: props.data.InstallmentCount,
        dataEncrypted: cardState.data,
      };
    }

    if (geoAvailable) {
      view.device.geolocation.latitude = geolocalization.coords.latitude;
      view.device.geolocation.longitude = geolocalization.coords.longitude;
    }

    return view;
  } catch (error) {
    throw new Error(`CheckoutMapper - ModelToViewModel = ${error}`);
  }
};

export const modelsToViewsModel = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.forEach(async (model) => {
      const view = modelToViewModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`CheckoutMapper - ModelsToViewsModel = ${error}`);
  }
};

export const viewModelToState = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.ResponseStatus = view.ResponseStatus;
    model.Uuid = view.Uuid;
    model.Email = view.Email;
    model.Name = view.Name;
    model.Type = view.Type;
    model.Password = view.Password || '';
    model.Document = DocumentFormat(view.Document);
    model.Status = view.Status;
    model.Groups = view.Groups;

    return model;
  } catch (error) {
    throw new Error(`CheckoutMapper - ViewModelToState = ${error}`);
  }
};
