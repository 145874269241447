import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Row,
  Form,
  Label,
  Media,
} from 'reactstrap';
import Model from '../../model/checkout';
import CHECKOUT from '../../const/checkout';
import Header from '../../containers/defaultHeader';
import Footer from '../../containers/defaultFooter';

class Resume extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { ...Model };
  }

  render() {
    const { statePostCheckout, listResume } = this.props;

    if (!listResume || !listResume.data) {
      return <Redirect to={{ pathname: '/404' }} />;
    }

    const model = listResume.data;
    const modelCheckout = statePostCheckout ? statePostCheckout.data : {};
    const actualCycle = model.InstallmentCount - model.Remaining.Installments;
    const actualParcel = model && model.BillingCycles[actualCycle];
    const statusChangePayment = model && model.Status !== 'CREATED' && (actualParcel.Status === 'PENDING' || actualParcel.Status === 'PROCESSING_ERROR' || actualParcel.Status === 'PROCESSING_DELEGATION_ERROR' || actualParcel.Status === 'PAYMENT_DENIED' || actualParcel.Status === 'PAYMENT_ERROR');

    return (
      <div className="container-page">
        <Header listResume={listResume} />
        <Row className="m-0 h-100">
          <Col md="9">
            <div className="app back-logo flex-row align-items-center">
              <Container>
                <Form onSubmit={this.handleSubmit}>
                  <Row className="justify-content-center">
                    <Col md="12" className="mt-0 mb-3">
                      <CardGroup>
                        <Card className="p-4 rounded-0" style={{ boxShadow: '1px 2px 2px 1px rgba(0, 0, 0, 0.2)' }}>
                          <Row>
                            <Col md="12" style={{ textAlign: 'center' }}>
                              <Media
                                object
                                className="mr-2"
                                src="assets/icons/check-icon.svg"
                                alt="check"
                                style={{ transform: 'rotate(-6deg)' }}
                              />
                              <Label className="m-0" style={{ fontSize: '26px', color: '#1a1a1a' }}>
                                <strong>
                                  Informações de pagamento atualizadas com sucesso
                                </strong>
                              </Label>
                            </Col>
                          </Row>
                        </Card>
                      </CardGroup>
                    </Col>
                    <Col
                      md="3"
                      id="ssl-mobile"
                      className="mb-3"
                      style={{
                        width: '108%',
                        marginLeft: '-14px',
                        marginRight: '-14px',
                        backgroundColor: '#f5f5f5',
                        display: 'none',
                      }}
                    >
                      <Row className="m-0 mt-5">
                        <Col className="flex-column d-flex align-items-center">
                          <Media
                            object
                            src={model.IconType}
                            alt="Logo"
                            className="mt-3 mb-2"
                            style={{ maxWidth: '130px' }}
                          />
                          <Label className="text-center border-bottom w-100 m-0 pb-3"><strong style={{ color: '#58595b' }}>{model.TextType}</strong></Label>
                        </Col>
                      </Row>
                      <Row className="m-0">
                        <Col md="12" className="pb-2 mt-3 mb-3">
                          <Label><strong className="label-side-info">Dados da proposta</strong></Label>
                        </Col>
                      </Row>
                      {
                        model.Cars && model.Type === 'AUTO' && model.Cars.map((car, idx) => (
                          <Row className="m-0">
                            <Col md="12" key={car.licensePlate}>
                              <span className="float-left pr-2 label-side-info">
                                {`${CHECKOUT.DETAILS.VEHICLE_PLATE} #${idx + 1}:`}
                              </span>
                              <span className="float-left fw-600 label-side-info">
                                {car.licensePlate}
                              </span>
                            </Col>
                          </Row>
                        ))
                      }
                      <Row className="m-0">
                        <Col md="12">
                          <span className="float-left pr-2 label-side-info">
                            {CHECKOUT.DETAILS.PROPOSAL_NUMBER}
                          </span>
                          <span className={`float-left fw-600 label-side-info pb-4 w-100 ${model.Type === 'AUTO' && 'border-bottom'}`}>
                            {model.ProposalNumber}
                          </span>
                        </Col>
                      </Row>
                      {model.Type !== 'AUTO' && (
                        <Col md="12">
                          <Label className="label-side-info pb-4 border-bottom w-100">
                            {model.Document.length === 10
                              ? CHECKOUT.DETAILS.CNPJ : CHECKOUT.DETAILS.CPF}
                            <strong className="ml-2 label-side-info">
                              {model.Document}
                            </strong>
                          </Label>
                        </Col>
                      )}
                    </Col>
                    <Col md="12" style={{ fontSize: '20px' }}>
                      <CardGroup>
                        <Card className="p-1 rounded-0 pr-5 pl-5 card-body-mobile" style={{ boxShadow: '1px 2px 2px 1px rgba(0, 0, 0, 0.2)' }}>
                          <CardBody className="pr-5 pl-5">
                            <Row>
                              <Col md="12">
                                <Label>
                                  {' '}
                                  <strong>Resumo do pagamento</strong>
                                </Label>
                              </Col>
                              <Col md="12" className="mb-5">
                                <p>
                                  Seu pagamento será processado em breve,
                                  você receberá o comprovante no email
                                  {' '}
                                  <b>
                                    {' '}
                                    {model.Email}
                                  </b>
                                </p>
                              </Col>
                            </Row>
                            <Card className="pl-5 pr-5 pt-3 pb-3 card-resume-value-mobile" style={{ borderColor: '#58595b' }}>
                              <Row>
                                <Col md="12" className="text-center mb-3" style={{ color: '#1a1a1a' }}>
                                  <Label>
                                    {`Informações de pagamento atualizadas em ${modelCheckout && modelCheckout.AuthorizedAt
                                      ? modelCheckout.AuthorizedAt.toDateTimeLocale() : (new Date()).toString().toDateTimeLocale()}`}
                                  </Label>
                                </Col>
                                {
                                  model.InstallmentValue > 0 && (
                                    <Col md="12" className="d-flex align-items-center resume-mobile">
                                      <Col md="1" className="text-left">
                                        <Media
                                          className="logo"
                                          object
                                          src="assets/icons/credit-card-icon.svg"
                                          alt="sompo"
                                          style={{ height: '72px' }}
                                        />
                                      </Col>
                                      <Col md="6" className="ml-3">
                                        <Label className="m-0">Crédito</Label>
                                      </Col>
                                      <Col md="5" className="p-0 text-center">
                                        <Label className="m-0">
                                          {
                                            model.ShowParcelInfo ? (
                                              `${model.InstallmentValue.BRLCurrency()}`
                                            ) : (
                                              `${statusChangePayment ? `${model.Remaining.Amount.BRLCurrency()} ${model.Remaining.Installments}x de ${model.InstallmentValue.BRLCurrency()}` : `${model.BalancePay.BRLCurrency()} ${model.InstallmentCount}x de ${model.InstallmentValue.BRLCurrency()}`}`
                                            )
                                          }
                                        </Label>
                                      </Col>
                                    </Col>
                                  )
                                }
                                <Col md="12">
                                  <hr style={{
                                    border: '1px solid rgba(88, 89, 91, 0.4)', borderStyle: 'none none solid none', margin: '0px 0px 15px 0px', padding: 0,
                                  }}
                                  />
                                </Col>
                                {
                                  !model.ShowParcelInfo && (
                                    <Col md="12" className="d-flex justify-content-end">
                                      <span className="fw-600 pr-2 font-xl" style={{ color: '#58595b' }}>
                                        {CHECKOUT.DETAILS.TOTAL_PRIZE}
                                      </span>
                                      <br />
                                      <span className="fw-600 mr-5 font-xl" style={{ color: '#58595b' }}>
                                        {`${statusChangePayment ? model.Remaining.Amount.BRLCurrency() : model.TotalPrize.BRLCurrency()}`}
                                      </span>
                                    </Col>
                                  )
                                }
                              </Row>
                            </Card>
                          </CardBody>
                        </Card>
                      </CardGroup>
                    </Col>
                  </Row>
                </Form>
              </Container>
            </div>
          </Col>
          <Col md="3" id="ssl-web" style={{ backgroundColor: '#f5f5f5' }}>
            <Row className="m-0 mt-5">
              <Col className="flex-column d-flex align-items-center">
                <Media
                  object
                  src={model.IconType}
                  alt="Logo"
                  className="mt-3 mb-2"
                  style={{ maxWidth: '130px' }}
                />
                <Label className="text-center border-bottom w-100 m-0 pb-3"><strong style={{ color: '#58595b' }}>{model.TextType}</strong></Label>
              </Col>
            </Row>
            <Row className="m-0">
              <Col md="12" className="pb-2 mt-3 mb-3">
                <Label><strong className="label-side-info">Dados da proposta</strong></Label>
              </Col>
            </Row>
            {
              model.Cars && model.Type === 'AUTO' && model.Cars.map((car, idx) => (
                <Row className="m-0">
                  <Col md="12" key={car.licensePlate}>
                    <span className="float-left pr-2 label-side-info">
                      {`${CHECKOUT.DETAILS.VEHICLE_PLATE} #${idx + 1}:`}
                    </span>
                    <span className="float-left fw-600 label-side-info">
                      {car.licensePlate}
                    </span>
                  </Col>
                </Row>
              ))
            }
            <Row className="m-0">
              <Col md="12">
                <span className="float-left pr-2 label-side-info">
                  {CHECKOUT.DETAILS.PROPOSAL_NUMBER}
                </span>
                <span className={`float-left fw-600 label-side-info pb-4 w-100 ${model.Type === 'AUTO' && 'border-bottom'}`}>
                  {model.ProposalNumber}
                </span>
              </Col>
            </Row>
            {model.Type !== 'AUTO' && (
              <Col md="12">
                <Label className="label-side-info pb-4 border-bottom w-100">
                  {model.Document.length === 10 ? CHECKOUT.DETAILS.CNPJ : CHECKOUT.DETAILS.CPF}
                  <strong className="ml-2 label-side-info">
                    {model.Document}
                  </strong>
                </Label>
              </Col>
            )}
          </Col>
        </Row>
        <Footer isResume="resume" />
      </div>
    );
  }
}

Resume.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ state: PropTypes.shape() }).isRequired,
  match: PropTypes.shape({ params: PropTypes.shape() }).isRequired,
  statePostCheckout: PropTypes.shape({ data: PropTypes.shape(), state: PropTypes.bool }).isRequired,
  listResume: PropTypes.shape({ data: PropTypes.shape(), state: PropTypes.bool }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  statePostCheckout: state.checkoutState.statePostCheckout,
  listResume: state.resumeState.listResume,
});

export default connect(mapStateToProps, mapDispatchToProps)(Resume);
