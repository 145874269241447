import { axiosInstance as axios } from '../lib/axios';
import Config from '../config/environments';
import { viewModelToModel } from '../mapper/resume';
import { LIST_RESUME } from '../actionTypes/resume';
import updateState from './common/common';

export const getCheckout = (checkoutToken) => async (dispatch) => {
  const base = {
    type: LIST_RESUME,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.RESUME.URL}/${checkoutToken}`;
  // console.log(checkoutToken);
  // const url = 'https://run.mocky.io/v3/2baf17ae-1e24-4c29-a1e2-4cf0f4ad5823';

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewModelToModel(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const getCheckout2 = (checkoutToken) => async (dispatch) => {
  const base = {
    type: LIST_RESUME,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.RESUME.URL}/${checkoutToken}`;

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewModelToModel(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};
