import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Row, Media, Col, Button,
} from 'reactstrap';

class DefaultHeader extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ShowInfo: true, PageName: '',
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { history } = nextProps;
    const { PageName, ShowInfo } = nextState;

    if (history.location.pathname !== PageName && PageName.length > 0 && !ShowInfo) {
      this.setState({ ShowInfo: true });

      return false;
    }

    return true;
  }

  render() {
    const { ShowInfo } = this.state;
    const { history, listResume } = this.props;
    const model = listResume && listResume.data;
    const env = process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE.trim();

    return (
      <>
        <div className="header-full" style={{ borderColor: model ? model.TopLineBackground : 'rgb(140, 199, 64)' }}>
          <Row style={{ margin: '0 auto', height: '123px' }}>
            <Col md="2" className="align-self-center">
              <Media
                className="logo"
                object
                src="assets/imgs/pay2bLogo.svg"
                alt="Logo"
                style={{ maxWidth: '130px' }}
              />
            </Col>
            <Col className="align-self-center text-center">
              {model && (
              <Media
                className="logo"
                object
                src={model.CompanyLogo}
                alt="Logo"
                style={{ maxHeight: 105 }}
              />
              )}
            </Col>
            <Col md="2" className="text-right align-self-center">
              <Media
                id="ssl-web"
                className="logo"
                object
                src="assets/imgs/ssl.svg"
                alt="SSL"
              />
              <Media
                id="ssl-mobile"
                className="logo"
                object
                src="assets/imgs/ssl-mobile.svg"
                alt="SSL"
                style={{
                  display: 'none',
                }}
              />
            </Col>
          </Row>
        </div>
        {env === 'HML' && ShowInfo && (
        <>
          <div className="container-identifier" />
          <Row className="identifier-environment">
            <Col xs="1" />
            <Col xs="10" className="d-flex justify-content-center">
              Você está acessando o ambiente de teste !
            </Col>
            <Col xs="1" className="p-0">
              <Button
                className="m-0 bg-transparent border-0"
                type="button"
                onClick={() => {
                  this.setState({ ShowInfo: false, PageName: history.location.pathname });
                }}
                onKeyDown={() => {
                  this.setState({ ShowInfo: false, PageName: history.location.pathname });
                }}
              >
                <i
                  className="fa fa-close text-white"
                />
              </Button>
            </Col>
          </Row>
        </>
        )}
      </>
    );
  }
}

DefaultHeader.defaultProps = {
};

DefaultHeader.propTypes = {
  listResume: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
  },
  dispatch,
);
const mapStateToProps = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DefaultHeader));
