import Model from '../model/billingCycle';

export const viewModelToModel = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.Amount = view.amount;
    model.BillingCycle = view.billingCycle;
    model.AuthCode = view.authCode;
    model.Id = view.id;
    model.OrderId = view.orderId;
    model.ProcessingDate = view.processingDate;
    model.Status = view.status;

    return model;
  } catch (error) {
    throw new Error(`ItemMapper - ViewModelToModel = ${error}`);
  }
};

export const viewsModelToModels = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.forEach(async (model) => {
      const view = viewModelToModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`ItemMapper - ViewsModelToModels = ${error}`);
  }
};

export const modelToViewModel = (model) => {
  try {
    if (!model) return null;

    const view = {};

    view.Sku = model.Sku;
    view.Name = model.Name;
    view.Value = model.Value;
    view.Quantity = model.Quantity;

    return view;
  } catch (error) {
    throw new Error(`ItemMapper - ModelToViewModel = ${error}`);
  }
};

export const modelsToViewsModel = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.forEach(async (model) => {
      const view = modelToViewModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`ItemMapper - ModelsToViewsModel = ${error}`);
  }
};

export const viewModelToState = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.Sku = view.Sku;
    model.Name = view.Name;
    model.Value = view.Value;
    model.Quantity = view.Quantity;

    return model;
  } catch (error) {
    throw new Error(`ItemMapper - ViewModelToState = ${error}`);
  }
};
